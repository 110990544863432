import { Form, Select, DatePicker, Button, Row, Col } from "antd";
import useOrdersFilters from "./useOrdersFilters";

const { RangePicker } = DatePicker;
const statuses = [
  {
    label: "معلق",
    value: "pending",
  },
  {
    label: "في الطريق",
    value: "on-way",
  },
  {
    label: "تم التوصيل",
    value: "delivered",
  },
  {
    label: "تم الالغاء",
    value: "canceled",
  },
];

const OrdersFilter = () => {
  const { onFilterChange, form } = useOrdersFilters();

  return (
    <Form form={form} layout="vertical" onFinish={onFilterChange}>
      <Row gutter={10}>
        <Col span={10}>
          <Form.Item label="Status" name="status">
            <Select options={statuses} mode="multiple" showArrow />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item label="Range" name="range">
            <RangePicker showTime />
          </Form.Item>
        </Col>
        <Col
          span={4}
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Filter
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default OrdersFilter;
