import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
} from "react-router-dom";

import { ProtectedRoute } from "@/services/auth/ProtectedRoute";

import { ROUTES } from "@/contstants";

import DefaultLayout from "@/layouts/DefaultLayout";

import {
  LoginPage,
  AllOrdersPage,
  CreateOrderPage,
  ChangePasswordPage,
  HooksFormPage,
} from "@/pages";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <DefaultLayout />
          </ProtectedRoute>
        }
      >
        <Route path={ROUTES.allOrders} element={<AllOrdersPage />} />
        <Route path={ROUTES.createOrder} element={<CreateOrderPage />} />
        <Route path={ROUTES.changePassword} element={<ChangePasswordPage />} />
        <Route path={ROUTES.hooksForm} element={<HooksFormPage />} />
      </Route>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Outlet />
          </ProtectedRoute>
        }
      >
        <Route path={ROUTES.login} element={<LoginPage />} />
      </Route>
    </>
  )
);

export default router;
