import {
  Table,
  Drawer,
  Button,
  Space,
  TreeSelect,
  Form,
  Input,
  Select,
} from "antd";
import { useTranslation } from "react-i18next";
import OrderForm from "../OrderForm";
import useOrdersTable from "./useOrdersTable";
import OrdersFilter from "../Filters";

const OrdersTable = () => {
  const { t } = useTranslation("orders");
  const {
    columns,
    rows,
    orders,
    onPagination,
    loading,
    loadingFormData,
    openDrawer,
    onCloseDrawer,
    selectedOrder,
    openRequestDriverDrawer,
    toggleRequestDriverDrawer,
    areasOptions,
    onRequestDriver,
    isAllowedToRequestDriver,
    isAllowedToChooseDriver,
    drivers,
  } = useOrdersTable();
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      {Boolean(isAllowedToRequestDriver) && (
        <Button
          type="primary"
          onClick={toggleRequestDriverDrawer}
          loading={loadingFormData}
        >
          {t("requestDriver")}
        </Button>
      )}
      <OrdersFilter />

      <Table
        dataSource={rows}
        columns={columns}
        onChange={onPagination}
        loading={loading}
        pagination={{
          pageSize: orders?.meta?.per_page,
          total: orders?.meta?.total,
          current: orders?.meta?.current_page,
        }}
      />
      <Drawer
        size="large"
        open={openDrawer}
        onClose={onCloseDrawer}
        placement="right"
      >
        <OrderForm isEdit order={selectedOrder} onCloseDrawer={onCloseDrawer} />
      </Drawer>
      <Drawer
        size="large"
        open={openRequestDriverDrawer}
        onClose={toggleRequestDriverDrawer}
        placement="right"
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <Form layout="vertical" onFinish={onRequestDriver}>
            <Form.Item
              label={t("requestDriverForm.selectCustomerArea")}
              name="area_id"
            >
              <TreeSelect
                treeData={areasOptions}
                treeDefaultExpandAll
                style={{ width: "100%" }}
                placeholder={t("requestDriverForm.selectCustomerArea")}
              />
            </Form.Item>

            <Form.Item
              name="order_number"
              label={t("requestDriverForm.orderNumber")}
              rules={[
                {
                  required: true,
                  message: t("requestDriverForm.validation.orderNumber"),
                },
                {
                  pattern: /^[0-9]*$/,
                  message: t("requestDriverForm.validation.onlyNumbers"),
                },
              ]}
            >
              <Input />
            </Form.Item>

            {isAllowedToChooseDriver && (
              <Form.Item
                name="driver_id"
                label={t("requestDriverForm.chooseDriver")}
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Select options={drivers} />
              </Form.Item>
            )}

            <Form.Item>
              <Button htmlType="submit" type="primary" loading={loading}>
                {t("requestDriverForm.confirm")}
              </Button>
            </Form.Item>
          </Form>
        </Space>
      </Drawer>
    </Space>
  );
};

export default OrdersTable;
