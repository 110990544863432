import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Form } from "antd";
import dayjs from "dayjs";
import { INITIAL_RANGE_END_TIME, INITIAL_RANGE_START_TIME } from "@/contstants";

type FormValues = {
  status: string[];
  range?: [number, number];
  start_time: number | undefined;
  end_time: number | undefined;
};

const useOrdersFilters = () => {
  const [searchParams, setSeatchParams] = useSearchParams();
  const [form] = Form.useForm();

  useEffect(() => {
    if (searchParams.get("page")) {
      return;
    }
    searchParams.set("start_time", String(INITIAL_RANGE_START_TIME));
    searchParams.set("end_time", String(INITIAL_RANGE_END_TIME));
    searchParams.set("page", "1");
    setSeatchParams(searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFilterChange = (values: FormValues) => {
    if (values.status?.length > 0) {
      searchParams.set("status", values.status?.join(","));
    } else {
      searchParams.delete("status");
    }
    if (values.range) {
      searchParams.set("start_time", String(values.range?.[0]));
      searchParams.set("end_time", String(values.range?.[1]));
    } else {
      searchParams.delete("start_time");
      searchParams.delete("end_time");
    }
    setSeatchParams(searchParams);
  };

  useEffect(() => {
    if (!searchParams.get("start_time") || !searchParams.get("end_time")) {
      return;
    }
    form.setFieldsValue({
      range: [
        dayjs(searchParams.get("start_time")),
        dayjs(searchParams.get("end_time")),
      ],
    });
  }, [searchParams, form]);

  return { onFilterChange, form, searchParams };
};

export default useOrdersFilters;
