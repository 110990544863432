import { message } from "antd";
import axiosInstance from "@/services/http/axiosInstance";
import { FormValues } from "./types";

export const postHooks = async (values: FormValues["hooks"]) => {
  const response = await axiosInstance.post("/api/vendor/web-hooks", values);
  return response.data;
};

export const getHooks = async () => {
  const response = await axiosInstance.get("/api/vendor/web-hooks");
  return response.data;
};

export const deleteHook = async (id: number) => {
  const response = await axiosInstance.delete(`/api/vendor/web-hooks/${id}`);
  return response.data;
};

export const editHook = async (
  id: number | null,
  values: FormValues["hooks"]
) => {
  if (!id) message.error("Please select a hook to edit");
  const response = await axiosInstance.put(
    `/api/vendor/web-hooks/${id}`,
    values
  );
  return response.data;
};
