import {
  Button,
  Card,
  Form,
  Input,
  Space,
  Descriptions,
  Row,
  Typography,
} from "antd";
import { CloseOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import useHooksForm from "./useHooksForm";

const { Text, Link } = Typography;

const HooksForm = () => {
  const { t } = useTranslation("hooks");
  const {
    form,
    onSubmitHooks,
    hooks,
    deleteHookHandler,
    isDeleteHook,
    selectHookToEdit,
    selecetedHookId,
    isEditOrCreate,
  } = useHooksForm();

  return (
    <>
      <Form
        form={form}
        name="hooks-form"
        autoComplete="off"
        initialValues={{ hooks: [{}] }}
        onFinish={onSubmitHooks}
      >
        <Card title={t("form.title")}>
          <Form.Item
            label={t("form.name")}
            name="name"
            rules={[
              { required: true, message: t("form.validations.required") },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("form.endPoint")}
            name="endpoint"
            rules={[
              { required: true, message: t("form.validations.required") },
              { type: "url", message: t("form.validations.url") },
            ]}
          >
            <Input />
          </Form.Item>

          {/* Headers Form.List */}
          <Form.Item label={t("form.headers")}>
            <Form.List name="headers">
              {(headers, headersOptions) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: 16,
                  }}
                >
                  {headers.map((subField) => (
                    <Space key={subField.key}>
                      <Form.Item noStyle name={[subField.name, "key"]}>
                        <Input placeholder={t("form.key")} />
                      </Form.Item>
                      <Form.Item noStyle name={[subField.name, "value"]}>
                        <Input placeholder={t("form.value")} />
                      </Form.Item>
                      <CloseOutlined
                        onClick={() => {
                          headersOptions.remove(subField.name);
                        }}
                      />
                    </Space>
                  ))}
                  <Button type="primary" onClick={() => headersOptions.add()}>
                    + {t("form.actions.addHeader")}
                  </Button>
                </div>
              )}
            </Form.List>
          </Form.Item>
        </Card>

        <Form.Item style={{ marginTop: 20 }}>
          <Button
            type="primary"
            htmlType="submit"
            block
            loading={isEditOrCreate}
          >
            {selecetedHookId
              ? t("form.actions.editHook")
              : t("form.actions.submitHook")}
          </Button>
        </Form.Item>
      </Form>

      {hooks.map((hook) => (
        <Row dir="ltr" key={hook.id} style={{ marginBottom: 20 }}>
          <Card
            title={t("info.title")}
            extra={
              <Space>
                <Button
                  icon={<DeleteOutlined />}
                  danger
                  loading={isDeleteHook && selecetedHookId === hook.id}
                  onClick={() => deleteHookHandler(hook.id)}
                />
                <Button
                  icon={<EditOutlined />}
                  onClick={() => {
                    selectHookToEdit(hook.id, hook);
                  }}
                />
              </Space>
            }
          >
            <Descriptions>
              <Descriptions.Item label={<Text strong>{t("info.name")}</Text>}>
                {hook.name}
              </Descriptions.Item>
              <Descriptions.Item
                label={<Text strong>{t("info.endPoint")}</Text>}
              >
                <Link
                  href={hook.endpoint}
                  target="_blank"
                  copyable={{ text: hook.endpoint }}
                >
                  {t("info.url")}
                </Link>
              </Descriptions.Item>
              <Descriptions.Item
                label={<Text strong>{t("info.lastHit")}</Text>}
              >
                {hook.last_hit
                  ? dayjs.unix(hook.last_hit).format("YYYY/MM/DD HH:mm:ss")
                  : "N/A"}
              </Descriptions.Item>
              <Descriptions.Item
                label={<Text strong>{t("info.headers")}</Text>}
              >
                <Descriptions>
                  {hook.headers?.map((header) => (
                    <Descriptions.Item key={header.key}>
                      {header.key}: {header.value}
                    </Descriptions.Item>
                  ))}
                </Descriptions>
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Row>
      ))}
    </>
  );
};

export default HooksForm;
