import { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import type { ColumnsType } from "antd/es/table";
import { useSearchParams } from "react-router-dom";
import {
  TablePaginationConfig,
  Button,
  Space,
  message,
  Typography,
} from "antd";
import {
  EditOutlined,
  CloseOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { ModalButton } from "@components/atoms";
import { Order, OrdersResponse, Area } from "@/types/orders";
import {
  getOrders,
  cancelOrder,
  getAreas,
  requestDriver,
  getDrivers,
} from "../actions";
import { localStorageKeys, useLocalStorage } from "::root/src/services/storage";

const { Text } = Typography;

interface DataType {
  key: string;
}

const useOrdersTable = () => {
  const { t } = useTranslation("orders");
  const [searchParams, setSearchParams] = useSearchParams();
  const { storedValue } = useLocalStorage(localStorageKeys.user, "{}");
  const isAllowedToRequestDriver =
    JSON.parse(storedValue).is_allow_to_request_driver;
  const [orders, setOrders] = useState<OrdersResponse>({
    data: [],
    meta: {
      total: 0,
      per_page: 0,
      current_page: 1,
    },
  });
  const [areas, setAreas] = useState<Area[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [openRequestDriverDrawer, setOpenRequestDriverDrawer] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingFormData, setLoadingFormData] = useState<boolean>(false);
  const [drivers, setDrivers] = useState<{ label: string; value: string }[]>(
    []
  );
  const isAllowedToChooseDriver =
    JSON.parse(storedValue).is_allow_to_choose_driver;

  const areasOptions = areas.map((area) => {
    return {
      title: area.name,
      value: area.id,
      disabled: true,
      children: area.zone.map((zone) => {
        return {
          title: zone.name,
          value: zone.id,
        };
      }),
    };
  });

  const getAllOrders = useCallback(async () => {
    if (!searchParams.get("page")) {
      return;
    }
    setLoading(true);
    const data = await getOrders({
      page: searchParams.get("page") ? Number(searchParams.get("page")) : 1,
      status: searchParams.get("status")?.split(",") || undefined,
      start_time: dayjs(searchParams.get("start_time")).unix() * 1000,
      end_time: dayjs(searchParams.get("end_time")).unix() * 1000,
    });
    setOrders(data);
    setLoading(false);
  }, [searchParams]);

  useEffect(() => {
    getAllOrders();
  }, [getAllOrders]);

  const onPagination = ({ current }: TablePaginationConfig) => {
    searchParams.set("page", String(current));
    setSearchParams(searchParams);
  };

  const onCloseDrawer = () => {
    setOpenDrawer(false);
    getAllOrders();
  };

  const toggleRequestDriverDrawer = async () => {
    if (areas.length === 0) {
      setLoadingFormData(true);
      const data = await getAreas();
      setAreas(data);
      if (isAllowedToChooseDriver) {
        const res = await getDrivers();
        setDrivers(
          res.data.map((driver: { name: string; id: string }) => ({
            label: driver.name,
            value: driver.id,
          }))
        );
        setLoadingFormData(false);
      } else {
        setLoadingFormData(false);
      }
    }
    setOpenRequestDriverDrawer(!openRequestDriverDrawer);
  };

  const onRequestDriver = async (values: {
    area_id: string;
    order_number: number;
    driver_id: string;
  }) => {
    try {
      setLoading(true);
      await requestDriver(
        values.area_id,
        values.order_number,
        values.driver_id
      );
      message.success(t("messages.driverRequestSuccess"));
      setLoading(false);
      toggleRequestDriverDrawer();
      getAllOrders();
    } catch (e) {
      setLoading(false);
      message.error(t("messages.genericFailure"));
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "#",
      dataIndex: "order_number",
      key: "order_number",
    },
    {
      title: t("table.address"),
      key: "address",
      render: (record: Order) => {
        return (
          <Space direction="vertical">
            {record.address}
            <Text mark>{record.area?.area_name}</Text>
          </Space>
        );
      },
    },
    {
      title: t("table.customer"),
      dataIndex: "customer_name",
      key: "customer_name",
    },
    {
      key: "phone",
      title: t("table.customerPhone"),
      dataIndex: "phone",
    },
    {
      key: "status",
      title: t("table.status"),
      dataIndex: "status",
    },
    {
      key: "updated_at",
      title: t("table.lastUpdate"),
      render: (record: Order) => {
        return (
          <Space direction="vertical">
            {dayjs(record.updated_at).format("DD/MM/YYYY HH:mm")}
          </Space>
        );
      },
    },
    {
      key: "driver",
      title: t("table.driver"),
      dataIndex: "driver",
      render: (driver: { name: string }) => {
        return driver?.name;
      },
    },
    {
      key: "customer_location",
      title: t("table.customerLocation"),
      render: (record: Order) => {
        return (
          record.drop_off_location && (
            <Button
              type="link"
              icon={<EnvironmentOutlined />}
              onClick={() => {
                window.open(
                  `https://www.google.com/maps/search/?api=1&query=${record.drop_off_location.latitude},${record.drop_off_location.longitude}`,
                  "_blank"
                );
              }}
            />
          )
        );
      },
    },
    {
      title: t("table.actions"),
      key: "action",
      render: (record: Order) => {
        const canEditOrCancel = record.status === "pending";

        return (
          canEditOrCancel && (
            <Space>
              <Button
                type="primary"
                icon={<EditOutlined />}
                onClick={() => {
                  setOpenDrawer(true);
                  setSelectedOrder(record);
                }}
              />

              <ModalButton
                buttons={[
                  {
                    title: t("cancelModal.actions.no"),
                    type: "default",
                    close: true,
                  },
                  {
                    title: t("cancelModal.actions.yes"),
                    type: "default",
                    danger: true,
                    loading,
                    onClick: async () => {
                      try {
                        setLoading(true);
                        await cancelOrder(record.id);
                        await getAllOrders();
                        message.success(t("messages.cancelOrder"));
                        setLoading(false);
                      } catch (e) {
                        setLoading(false);
                        message.error(t("messages.genericFailure"));
                      }
                    },
                  },
                ]}
                icon={<CloseOutlined />}
                dangerButton
                buttonType="primary"
                modalTitle={t("cancelModal.title")}
              >
                {t("cancelModal.body")} # {record.order_number}
              </ModalButton>
            </Space>
          )
        );
      },
    },
  ];

  const rows = orders?.data.map((order: Order) => {
    return {
      key: order.id,
      ...order,
    };
  });

  return {
    columns,
    rows,
    orders,
    onPagination,
    loading,
    loadingFormData,
    openDrawer,
    onCloseDrawer,
    selectedOrder,
    toggleRequestDriverDrawer,
    openRequestDriverDrawer,
    areasOptions,
    onRequestDriver,
    isAllowedToRequestDriver,
    isAllowedToChooseDriver,
    drivers,
  };
};

export default useOrdersTable;
