import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Row, message } from "antd";
import { useTranslation } from "react-i18next";
import axiosInstance from "@/services/http/axiosInstance";
import { useLocalStorage, localStorageKeys } from "::root/src/services/storage";
import { ROUTES } from "::root/src/contstants";

const LoginPage = () => {
  const { t } = useTranslation("login");
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const { setValue } = useLocalStorage(localStorageKeys.token, "");
  const { setValue: setUser } = useLocalStorage(localStorageKeys.user, "");
  const onFinish = (values: any) => {
    setLoading(true);
    axiosInstance
      .post("/api/auth/login", {
        email: values.username,
        password: values.password,
      })
      .then(async (res) => {
        setValue(res.data.token);
        setUser(res.data.user);

        setLoading(false);
        navigate(ROUTES.allOrders);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.status === 401) {
          messageApi.open({
            content: "اسم المستخدم او كلمة المرور غير صحيحة",
            type: "error",
          });
        } else if (err.response.status === 403) {
          messageApi.open({
            content: "غير مسموح لك بالدخول للنظام",
            type: "error",
          });
        } else {
          messageApi.open({
            content: "حدث خطأ ما، الرجاء اخبار احد المسؤولين",
            type: "error",
          });
        }
      });
  };

  return (
    <Row align="middle" justify="center" dir="rtl" style={{ height: "100vh" }}>
      {contextHolder}
      <Form
        name="basic"
        layout="vertical"
        initialValues={{ remember: true }}
        style={{ minWidth: 600 }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label={t("form.userName")}
          name="username"
          rules={[
            {
              required: true,
              message: t("form.validations.userName.required"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("form.password")}
          name="password"
          rules={[
            {
              required: true,
              message: t("form.validations.password.required"),
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t("form.actions.login")}
          </Button>
        </Form.Item>
      </Form>
    </Row>
  );
};

export default LoginPage;
