import { Typography, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import OrderForm from "../OrderForm";

const { Title } = Typography;

const CreateOrder = () => {
  const { t } = useTranslation("orders");
  return (
    <Row>
      <Col span={24}>
        <Title>{t("form.title")}</Title>
      </Col>
      <Col span={24}>
        <OrderForm />
      </Col>
    </Row>
  );
};

export default CreateOrder;
