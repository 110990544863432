import { Suspense } from "react";
import { RouterProvider } from "react-router-dom";
import { ConfigProvider } from "antd";
import { useTranslation } from "react-i18next";
import { AuthProvider } from "@/contexts";
import router from "@/navigation/router";
import "./App.css";

function App() {
  const { i18n } = useTranslation();
  return (
    <Suspense fallback="loading">
      <ConfigProvider
        direction={i18n.dir()}
        theme={{
          token: {
            fontFamily:
              i18n.resolvedLanguage === "en"
                ? "Roboto Condensed"
                : "Noto Kufi Arabic",
          },
        }}
      >
        <AuthProvider>
          <RouterProvider router={router} />
        </AuthProvider>
      </ConfigProvider>
    </Suspense>
  );
}

export default App;
