import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import type { MenuProps } from "antd";
import { message } from "antd";
import { useTranslation, Translation } from "react-i18next";
import { ROUTES } from "@/contstants";
import axiosInstance from "@/services/http/axiosInstance";
import { localStorageKeys, useLocalStorage } from "::root/src/services/storage";
import { getBalanceAndOrdersCount } from "./actions";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem(
    <NavLink to={ROUTES.allOrders}>
      <Translation>{(t) => <>{t("layout.header.orderList")}</>}</Translation>
    </NavLink>,
    ROUTES.allOrders
  ),
  getItem(
    <NavLink to={ROUTES.createOrder}>
      <Translation>{(t) => <>{t("layout.header.createOrder")}</>}</Translation>
    </NavLink>,
    ROUTES.createOrder
  ),
];

export default function useDefaultLayout() {
  const { i18n, t } = useTranslation();
  const nextLanguage = i18n.resolvedLanguage === "en" ? "العربية" : "English";
  const nextLocale = i18n.resolvedLanguage === "en" ? "ar" : "en";
  const { storedValue } = useLocalStorage(localStorageKeys.user, "");
  const navigate = useNavigate();
  const [vendorInfo, setVendorInfo] = useState<{
    balance: number;
    orders_count: number;
  }>({ balance: 0, orders_count: 0 });

  const userName = JSON.parse(storedValue || "{}")?.name;

  const menuItems: MenuProps["items"] = [
    {
      key: ROUTES.changePassword,
      label: t("layout.header.changePassword"),
      onClick: () => navigate(ROUTES.changePassword),
    },
    {
      key: ROUTES.hooksForm,
      label: t("layout.header.hooks"),
      onClick: () => navigate(ROUTES.hooksForm),
    },
    {
      key: "LanguageSwitcher",
      label: nextLanguage,
      onClick: () => i18n.changeLanguage(nextLocale),
    },
    {
      key: ROUTES.login,
      label: t("layout.header.logout"),
      danger: true,
      onClick: () => {
        axiosInstance.post("/api/auth/logout").then(() => {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          window.location.replace(ROUTES.login);
        });
      },
    },
  ];

  useEffect(() => {
    getBalanceAndOrdersCount()
      .then((data) => {
        setVendorInfo(data);
      })
      .catch(() => {
        setVendorInfo({ balance: 0, orders_count: 0 });
        message.error("حدث خطأ ما اثناء جلب بيانات الرصيد وعدد الطلبات");
      });
  }, []);

  const { pathname } = useLocation();
  return { items, pathname, menuItems, userName, vendorInfo };
}
