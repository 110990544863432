import { useEffect, useState } from "react";
import { Form, message } from "antd";
import { FormValues, HooksData } from "./types";
import { getHooks, postHooks, deleteHook, editHook } from "./actions";

const useHooksForm = () => {
  const [form] = Form.useForm();
  const [hooks, setHooks] = useState<HooksData[]>([]);
  const [isDeleteHook, setIsDeleteHook] = useState(false);
  const [selecetedHookId, setSelectedHookId] = useState<number | null>(null);
  const [isEditOrCreate, setIsEditOrCreate] = useState(false);

  const getHooksData = async () => {
    const response = await getHooks();
    setHooks(response?.data);
  };

  const editHookHandler = async (values: FormValues["hooks"]) => {
    try {
      await editHook(selecetedHookId, values);
      await getHooksData();
      setSelectedHookId(null);
      message.success("Hook updated successfully");
    } catch (error) {
      message.error("Something went wrong");
    }
  };

  const onSubmitHooks = async (values: FormValues["hooks"]) => {
    setIsEditOrCreate(true);
    if (!selecetedHookId) {
      await postHooks(values);
      await getHooksData();
      setIsEditOrCreate(false);
      form.resetFields();
      return;
    }
    if (!values?.headers) {
      // eslint-disable-next-line no-param-reassign
      delete values?.headers;
    }
    await editHookHandler(values);
    setIsEditOrCreate(false);
    form.resetFields();
  };

  const deleteHookHandler = async (id: number) => {
    try {
      setSelectedHookId(id);
      setIsDeleteHook(true);
      await deleteHook(id);
      await getHooksData();
      message.success("Hook deleted successfully");
      setIsDeleteHook(false);
      setSelectedHookId(null);
    } catch (error) {
      setIsDeleteHook(false);
      setSelectedHookId(null);
    }
  };

  const selectHookToEdit = async (id: number, values: HooksData) => {
    setSelectedHookId(id);
    window.scrollTo(0, document.body.scrollTop);
    form.setFieldsValue(values);
  };

  useEffect(() => {
    getHooksData();
  }, []);

  return {
    form,
    onSubmitHooks,
    hooks,
    deleteHookHandler,
    isDeleteHook,
    selectHookToEdit,
    editHookHandler,
    selecetedHookId,
    isEditOrCreate,
  };
};

export default useHooksForm;
